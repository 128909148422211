import { BaseService } from './_BaseService';

export class ShortLinkService extends BaseService {
    getShortLink = async (id, source) => {
        return await this.get(`${id}?source=${source}`);
    }

    countSMSClick = async () => {
        return await this.get(`count-sms-click`);
    }
}