import { useEffect } from 'react';

import './App.css';
import "primereact/resources/themes/lara-light-indigo/theme.css"; 
import "primereact/resources/primereact.min.css";  
import "primeicons/primeicons.css";  
import Loader from './shared_components/Loader/Loader';
import { ShortLinkService } from './services/ShortLinkService';

function App() {

  useEffect(()=>{

    const getShortLink = async () => {
      let url = "https://ecommerce.poweredbyelevation.com";

      const isSMSClick = window.location.pathname.startsWith("/s/");
      console.log("isSMSClick", isSMSClick)
      const shortLinkCode = window.location.pathname.substring(isSMSClick ? 3 : 1);
      console.log("shortLinkCode", shortLinkCode)
      if((shortLinkCode || "") !== "")
      {
        
        const service = new ShortLinkService();
        const shortLink = await service.getShortLink(shortLinkCode, isSMSClick ? "SMS" : "EMAIL");
        if(shortLink.url){
          url = shortLink.url;
        }
      }
      else {
        const service = new ShortLinkService();
        await service.countSMSClick();
      }

      console.log(url);
      window.location.href = url;
    }

    getShortLink();
    
  },[]);


  return (
    <Loader/>
  );
}

export default App;
